import React, { useEffect } from "react";
import { Rive } from "@rive-app/react-canvas";
import("./App.css");

function App() {
  useEffect(() => {
    const canvas = document.getElementById("rumplelookscanvas");

    const rumplelooks = new Rive({
      src: "images/eyes-movingv3.riv",
      canvas: canvas,
      autoplay: true,
      artboard: "Artboard",
      stateMachines: ["State Machine 1"],
      onLoad: () => {
        rumplelooks.resizeDrawingSurfaceToCanvas();
        const inputs = rumplelooks.stateMachineInputs("State Machine 1");

        const xInput = inputs?.find((input) => input.name === "xposition");
        const yInput = inputs?.find((input) => input.name === "yposition");

        if (!xInput || !yInput) {
          console.error("State machine inputs not found: xposition, yposition");
          return;
        }

        const maxWidth = window.innerWidth;
        const maxHeight = window.innerHeight;

        // Mousemove event handler
        const handleMouseMove = (e) => {
          try {
            if (xInput && yInput) {
              xInput.value = (e.clientX / maxWidth) * 100;
              yInput.value = 100 - (e.clientY / maxHeight) * 100;
            }
          } catch (err) {
            console.error("Error updating input values:", err);
          }
        };

        // Register mousemove listener only after inputs are initialized
        window.addEventListener("mousemove", handleMouseMove);

        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener("mousemove", handleMouseMove);
        };
      },
    });

    // Cleanup function to dispose of Rive instance on unmount
    return () => rumplelooks.cleanup();
  }, []);

  return (
    <div className="App-header">
      <div className="App-logo">
        <canvas
          id="rumplelookscanvas"
          width="500"
          height="500"
        ></canvas>
      </div>
    </div>

  );
}

export default App;
